@use '../../../../../../../styles/colors.scss';

.summary-packaging {
  background: colors.$grey-50;
  border: 1px solid colors.$slightly-gray;
  padding: 24px;
  margin-top: 24px;

  &__total {
    list-style: none;
    display: grid;
    grid-auto-columns: 1fr;
    padding: 0;
    margin: 12px 0 0;

    li {
      grid-row: 1;
    }
  }

  &__block {
    border-top: 1px solid colors.$slightly-gray;
    position: relative;
    margin-top: 10px;
  }

  &__block-delivery {
    position: relative;
  }

  &__buttons{
    position: absolute;
    top: -8px;
    right: -12px;
    display: flex;
  }

  &__tooltipContainer{
    padding: 8px;
  }

  &__delivery {
    margin-top: 24px;
    background: colors.$grey-200;
    border: 1px dashed colors.$slightly-gray;
    padding: 24px;
  }

  &__list {
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px 24px;
    margin-bottom: 0;
    margin-top: 0;
  }

  &__list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    > p {
      position: relative;
      z-index: 1;
      background: #EEEEEE;

      &:first-child {
        padding: 0 4px 0 0;
      }
    }
  }

  &__list-item::before {
    content: '';
    position: absolute;
    left: calc(100% - 100%);
    right: 0;
    bottom: 4px;
    transform: translateY(-50%);
    height: 1px;
    border-bottom: 1px dashed colors.$slightly-gray;
    z-index: 0;
  }

  &__completed{
    margin-top: 24px;
    background: colors.$grey-200;

    &-preInfo{
      display: flex;
      justify-content: space-between;
    }

    .completed__list-item > p:before{
      background: colors.$grey-200;
    }
  }
}
