@use '../../../styles/colors.scss';

.menu {
  width: 272px;
  background: colors.$white;
  height: 100%;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  padding: 16px 0;

  .menu__button{
    min-height: 36px !important;
    padding-left: 40px;
  }

  svg {
    margin-right: 8px;
  }
}
