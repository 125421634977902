@use '../../../../../styles/colors.scss';

.settings-auth {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px 24px;
  grid-template-areas:
      "title ."
      "old_password ."
      "new_password new_password2"
      "button .";

  &__title { grid-area: title; }
  &__old_password { grid-area: old_password; }
  &__new_password { grid-area: new_password; position: relative; }
  &__new_password2 { grid-area: new_password2; }

  &__button, &__title {
    grid-column: span 2
  }

  &__title {
    line-height: 1.2;
  }

  &__passwordRequirements{
    position: relative;
    display: block;
    padding: 4px 10px;
  }

}
