.error-page-content {
  width: 510px;
  margin: auto;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image{
    width: 172px;
    height: 132px;
  }
}

#eyesErrorPage {
  position: absolute;
  z-index: 1;
  top: 128px;
  left: 180px;
}
