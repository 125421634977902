@use '../../../styles/colors.scss';

.calculator {

  &__group {
    padding: 24px;

    &-title{
      grid-column: span 2;
      padding-bottom: 24px;
    }

    &-smallMargin{
      margin-top: 20px;
    }

    &_noBorder {
      border: none;
      margin-bottom: 0;
    }
  }

  &__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-bottom: 24px;
    border: 1px dashed colors.$slightly-gray;
    background: colors.$grey-50;
    padding: 24px;
  }

  padding-bottom: 24px;
}
