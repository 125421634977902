@use '../../../../../styles/colors.scss';

.calculation {
  background: colors.$white;
  margin-bottom: 32px;
  box-shadow: 0 1px 3px 0 colors.$slightly-gray;

  &__countries{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid colors.$slightly-gray;
    padding: 16px 24px;
  }

  &__flag{
    display: flex;
    align-items: center;
  }

  &__route{
    &_isActual{
      color: colors.$green-dark;
    }

    &:not(&_isActual){
      color: colors.$dark-red;
    }
  }

  &__accordion{
    display: flex;
    width: 100%;
  }

  &__print-version{
    display: none;
  }

  &__content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 8px;
  }

  &__content-right{
    text-align: right;
    margin-right: 16px;
  }

  &__details{
    border: 1px solid colors.$slightly-gray;
  }

  &__select-action{
    width: 320px;
  }

  &__bottom{
    padding: 24px;
    border-top: 1px solid colors.$slightly-gray;
  }
}
