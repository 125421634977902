@use '../../../../../../../../../styles/colors';

.payments {

  &__completed{

    margin-top: 32px;

    .completed:last-child {
      margin-bottom: 0;
    }
  }

}


.payments-tabs{
  position: relative;

}

.payments-tabs-completed{
  margin-top: 24px;
}
