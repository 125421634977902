.wrapper {
  max-width: 736px;
  margin-top: 24px;
  width: 90%;
  min-height: 600px;

  @media screen and (max-width: 1024px)  {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1024px)  {
    margin-left: 24px;
  }

  &__title{
    display: flex;
    justify-content: space-between;
  }

}

.layout {
  display: flex;
  align-items: flex-start;
}
