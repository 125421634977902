.registration {
  width: 528px;

  &__form{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px 22px;
    margin: 16px 0 12px;
  }

  &__email{
    grid-column: span 2;
  }
}

