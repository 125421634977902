@use '../../../../../styles/colors.scss';

.summary-calculator {
  background: colors.$lime;
  margin-bottom: 32px;

  &__info{
    background: colors.$lime;
    margin: 24px;
    border: 1px solid colors.$slightly-gray;
  }

  &__buttons{
    display: grid;
    gap: 14px 0;
    margin: 24px;
    background: colors.$white;
    border: 1px solid colors.$slightly-gray;
    padding: 24px 24px 24px;

    &:not(&-one){
      grid-template-rows: 1fr 1fr;
    }
  }

  &__actions{
    margin: 24px 24px 0;
    padding-bottom: 24px;
  }

  &__print-version{
    display: none;
  }
}
