@use '../../../../../../styles/colors.scss';

.summary-calculator {

  box-shadow: 0 1px 3px 0 colors.$slightly-gray,
  0 1px 1px 0 colors.$slightly-gray,
  0 2px 1px -1px colors.$border-gray;

  &__item{
    display: flex;
    justify-content: space-between;

    &:not(&_no-padding){
      padding: 16px 32px 16px 24px;
    }

    &:not(&_no-padding):not(&_additional):not(&_notification) {
      border-bottom: 1px solid colors.$slightly-gray;
      height: 58px;
    }

    &_no-padding {
      padding: 5px 16px 4px 8px;
      width: 100%;
    }

    &_additional{
      background: colors.$beige;
      margin-bottom: 1px;
    }

    &_notificaiton-container{
      margin: 0 22px 16px 46px;
    }
  }

  &__additional{
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 99%;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-left{
      display: flex;
    }
  }

}
