.packaging-additional {

  &__fields {
   display: grid;
   grid-template-areas: "addField . ."
   "cancelButton closeButton closeButton";
   grid-template-columns: 1fr 1fr 1fr;
   margin-top: 24px;
   gap: 24px;
  }

  &__addField { grid-area: addField; }
  &__cancelButton { grid-area: cancelButton; }
  &__closeButton { grid-area: closeButton; }
}
