.app-bar {

  &__toolbar{
    display: flex;
    justify-content: space-between;
  }

  &__rightSide{
    display: flex;
    align-items: center;
  }

  &__exitContainer{
    padding: 0 20px 8px;
  }

  &__topContainer{
    padding: 20px 16px;
  }

  &__exitButton{
    cursor: pointer;
  }

  &__currency {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;

    &-text{
      font-size: 14px;
      margin-right: 4px;
      display: block;
    }
  }

  &__menuContainer{
    margin: 8px 0 10px;

    &_language {
      margin: 8px 0 4px;
    }
  }

  &__currenciesListContainer{
    margin: 8px 0;
  }

  &__bottomContainer{
    padding: 0 16px;
  }

  &__bottomButton{
    width: 100px;
    display: block;
    padding: 5px;
    cursor: pointer;
  }
}
