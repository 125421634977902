.customs-title {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__left{
    display: flex;

    &_marginRight{
      margin-right: 24px;
    }
  }

  &__right{
    text-align: right;
    margin-right: 18px;
  }

  svg {
    margin-right: 14px;
    margin-top: 2px;
  }

}
