@use '../../../../../../../styles/colors.scss';

.invoice-form {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  margin-bottom: 24px;

  &__title {
    display: flex;

    svg {
      margin-right: 24px;
    }
  }

  &__description, &__location_city, &__map{
    grid-column: span 3;
  }

  &__text_bold {
    font-weight: 500;
  }
}

.invoice-title {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__left{
    display: flex;
    width: 70%;
  }

  &__information{
    width: 100%;
  }

  &__right{
    text-align: right;
    margin-right: 18px;
  }

  svg {
    margin-right: 14px;
    margin-top: 2px;
  }

  &__name{
    max-width: 95%;
  }

}

.invoice{

  &__flag-info{
    display: flex;
    align-items: start;
    line-height: 1;

    &-text{
      line-height: 1.6;
    }
  }

  &__details{
    margin: 24px;
  }

  &__product-count{
    color: colors.$light-text-disabled;
    margin-left: 5px;
    font-weight: 500;
  }

}


.text_bold {
  font-weight: 500;
}
