@use '../../../../../styles/colors.scss';

.settings-company {

  display: grid;
  gap: 32px 24px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas:
      "inn ."
      "company_name ."
      "address ."
      "contact_email phone"
      "button .";

  &__inn { grid-area: inn; }
  &__company_name { grid-area: company_name; grid-column: span 2; }
  &__address { grid-area: address; grid-column: span 2;}
  &__contact_email { grid-area: contact_email; }
  &__phone { grid-area: phone; height: 56px;}
  &__button { grid-area: button; grid-column: span 2; }

  &__phone {

    &-error{
      color: colors.$red;
      font-weight: 400;
      font-size: 0.75rem;
      margin-left: 14px;
      margin-top: 5px;
      display: block;
    }

    .special-label {
      color: colors.$light-gray;
    }

    .react-tel-input .form-control {
      width: 100%;
      border-radius: 0;
      padding: 16.5px 58px;
      height: 56px;

      &.invalid-number {
        border: 1px solid colors.$red;
      }
    }

  }
}
