@use '../../../../../styles/colors.scss';

.title-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 16px;

  &__name{
    color: colors.$light-text-disabled;
  }
}

.text {
  display: flex;
  align-items: center;

  &__title {
    padding-left: 8px;
  }

  &_disabled{
    padding-left: 40px;
  }
}

