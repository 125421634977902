@use '../../../../../../../styles/colors.scss';

.additional-company-card {
  margin-bottom: 24px;

  &:not(&_active){
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  }

  &_active{
    border: 1px solid colors.$slightly-gray;
  }

  &__logo {
    font-size: 24px;
    color: colors.$white;
    height: 40px;
    width: 40px;
    background: colors.$strong-gray;
    display: inline-block;
    text-align: center;
    padding: 6px;
    margin-right: 16px;
  }

  &__title{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 16px;
    padding: 24px 24px 8px;
  }

  &__left{
    display: flex;
  }

  &__right{
    button{
      margin-right: -4px !important;
    }
  }

  &__rating-container{
    display: flex;
    align-items: center;
  }

}

.service-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-left: 12px;

  &__additionalInfo{
    color: colors.$light-text-disabled;
  }

  &__title{
    margin-top: 8px;
    margin-bottom: 4px;
  }

  &__showMore{
    margin-right: 18px;
  }

  &__inactive{
    display: flex;
    justify-content: space-between;
    padding: 8px;
    align-items: center;

    &_checked{
      background: colors.$light-blue-opacity;
    }
  }
}
