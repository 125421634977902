@use '../../../../../../../styles/colors.scss';

.certification-manual{

  &__container {
    padding: 24px;
    border: 1px dashed colors.$slightly-gray;
    background: colors.$grey-50;
  }

  &__total{
    font-weight: 500;
    margin-left: 8px
  }
}
