.dictionaries-addModal {
  padding: 32px 24px;

  &__notification {
    margin-top: 32px;
  }

  &__buttons {
    display: flex;
    padding: 0 24px 32px;
    justify-content: space-between;
  }

}
