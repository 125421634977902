@use '../../../../../../../../../styles/colors.scss';

.certification-code {
  padding: 22px 28px 18px;
  margin-bottom: 24px;

  &_hasCertificate{
    background: colors.$lime;
    border: 1px solid colors.$slightly-gray;
  }

  &:not(&_hasCertificate){
    background: colors.$light-beige;
    border: 1px dashed colors.$slightly-gray;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin: 28px 0 8px;
  }

}
