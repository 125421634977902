.no-match-content {
  width: 490px;
  margin: auto;
  text-align: center;
  position: relative;
}

#eyesNotFound {
  position: absolute;
  z-index: 1;
  top: 217px;
  left: 220px;
}
