@use '../../../../../styles/colors.scss';

.settings-interface {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px 24px;

  &__button, &__title, &__timezone {
    grid-column: span 2
  }

  &__title {
    line-height: 1.2;
  }

}
