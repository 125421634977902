.currency-removeModal {
  &__list{
    padding: 0 0 0 24px;
    margin: 0;
  }

  &__list-item{
    font-weight: 500;
    line-height: 1.5;
  }
}
