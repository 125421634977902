@use '../../../../../../../../../../../styles/colors';

.payment-item{

  border: 1px dashed colors.$slightly-gray;
  padding: 24px;
  margin-top: 24px;
  background: colors.$grey-50;

  position: relative;

  &__menuButtons{
    position: absolute;
    top: 16px;
    right: 20px;
  }

  &__form-row {
    display: grid;
    gap: 32px 24px;
    grid-template-columns: 1fr 1fr 1fr;

    &:not(:last-child){
      margin: 16px 0;
    }

    &:last-child{
      margin: 16px 0 0;
    }
  }

}
