.notification {

  &__title {
    letter-spacing: 0.15px;
  }

  &__description {
    letter-spacing: 0.17px;
  }

}

