@use '../../../../../../../styles/colors.scss';

.search-container {
  padding: 24px;
  background: colors.$white;
  margin: 0 24px 8px;
}

.searchForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 12px;
  position: relative;

  &__city {
    grid-column: span 2;
    max-width: 92%;

    &_big {
      max-width: 968px;
    }
  }

  &__line {
    position: absolute;
    right: 18px;

    top: 28px;
    width: 36px;

    &_isBig {
      right: 18px;
    }
  }

  &__change-button{
    top: -74px;
    right: -14px;
  }

  &__adornment{
    border: 1px solid;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }

  &__adornment-container {
    display: flex;
    align-items: center;

    img {
      margin-left: 10px;
    }

    .MuiInputAdornment-root .MuiTypography-root{
      color: colors.$black-dark;
      font-weight: 900;
    }
  }
}
