@use './styles/media.scss';
@use './styles/colors.scss';

.app {
  display: grid;
  padding-bottom: 106px;
  background: colors.$beige;
  position: relative;
  min-height: 100vh;
  min-width: 1440px
}
