@use '../../../../../../../../../styles/colors.scss';

.certificate-company-card {
  margin-bottom: 24px;

  &:not(&_active){
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  }

  &_active{
    border: 1px solid colors.$slightly-gray;
  }

  &__logo {
    font-size: 24px;
    color: colors.$white;
    height: 40px;
    width: 40px;
    background: colors.$strong-gray;
    display: inline-block;
    text-align: center;
    padding: 6px;
    margin-right: 16px;
  }

  &__main-content{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 24px 24px 8px;
  }

  &__left{
    display: flex;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(&_active){
      width: 260px;
    }
  }

  &__rating-container{
    display: flex;
    align-items: center;
  }
}
