@use '../../../../../../../styles/colors.scss';

.preferences {
  background: colors.$grey-200;
  padding: 24px;
  margin-bottom: 16px;

  &__list{
    margin: 0;
    padding: 0 0 0 20px;
  }
}
