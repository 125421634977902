@use '../../../../../styles/colors.scss';

.settings-account {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px 24px;

  &__button {
    grid-column: span 2
  }

  &__phone {

    height: 56px;

    &-error{
      color: colors.$red;
      font-weight: 400;
      font-size: 0.75rem;
      margin-left: 14px;
      margin-top: 5px;
      display: block;
    }

    .special-label {
      color: colors.$light-gray;
    }

    .react-tel-input .form-control {
      width: 100%;
      border-radius: 0;
      padding: 16.5px 58px;
      height: 56px;

      &.invalid-number {
        border: 1px solid colors.$red;
      }
    }

  }
}
