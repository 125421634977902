.modal-content {
  padding: 32px 24px;

  &__list {
    list-style: none;
    margin: 28px 0;
    padding: 0;
  }

  &__link{
    display: block;
    margin-bottom: 16px;
  }

  &__buttons {
    display: flex;
  }
}
