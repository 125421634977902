@use '../../../../../../../../../../../../../styles/colors';

.payment-item-edit{
  position: relative;

  &__menuButtons{
    position: absolute;
    top: 16px;
    right: 12px;
  }

  &__form-row {
    display: grid;
    gap: 32px 24px;
    grid-template-columns: 1fr 1fr 1fr;

    &:not(:last-child){
      margin: 16px 0;
    }

    &:last-child{
      margin: 16px 0 0;
    }
  }

  &__buttons{
    display: flex;
  }

}
