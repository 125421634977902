@use '../../../styles/colors.scss';

.right-panel {
  padding-bottom: 32px;

  &__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 4px;
      transform: translateY(-50%);
      height: 1px;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
      z-index: 0;
    }

    &-label {
      line-height: 1;
      display: flex;
      align-items: center;
      letter-spacing: 4px;
      font-weight: 500;
      background: colors.$white;
      z-index: 1;
    }

    &-value {
      line-height: 1;
      font-weight: 500;
      background: colors.$white;
      z-index: 1;
    }

    &_isCompleted{
      .right-panel__total-label, .right-panel__total-value  {
        background: colors.$lime;
      }
    }

  }
}

.summary-total {
  .no-data {
    font-style: italic;
    color: colors.$black;

    a {
      color: colors.$black;
      text-decoration: underline;
    }
  }
}
