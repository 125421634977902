.auth {
  margin: auto;
  width: 408px;

  &__card {
    display: grid;
    flex-grow: 1;
    margin-left: auto;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  &__input-adornment {
    cursor: pointer;
  }

  &__forget-password{
    text-align: right;
  }
}


