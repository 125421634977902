@use '../../../../../../../styles/colors.scss';

.customs {

  &__caption {
    color: colors.$light-gray;
    letter-spacing: 0.4px;
  }

  &__name{
    color: colors.$light-gray;
    letter-spacing: 0.15px;

    b {
      font-weight: 500;
      color: colors.$black;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;

    > div:first-child {
      width: 50%;
    }

    > div:nth-child(2) {
      width: 48%;
    }

    &-open {
      margin-bottom: 24px;
    }
  }

}
