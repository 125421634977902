@use '../../../../../styles/colors.scss';

.additional-title {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__left{
    display: flex;
  }

  &__right{
    text-align: right;
    margin-right: 24px;
  }

  svg {
    margin-right: 16px;
    margin-top: 6px;
  }

  &__count {
    color: colors.$light-text-disabled-opacity;
  }

}
