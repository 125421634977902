@use '../../../styles/colors.scss';

.footer {
  background: colors.$black;
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 106px;
}

.footer__logo {
  display: grid;
  grid-template-columns: 1fr;
  width: 160px;
  margin-left: 50%;
  left: -80px;
  gap: 12px 0;
  position: absolute;
  top: 26px;
}


.footer .footer__link {
  color: colors.$white;
  text-decoration: underline;

  &:hover{
    color: colors.$blue;
    text-decoration: underline;
  }
}
