@use '../../../styles/colors.scss';

.settings {

  margin-bottom: 28px;
  padding-top: 40px;

  @media screen and (max-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1024px) {
    margin-left: 32px;
    margin-right: 32px;
  }

  &__wrapper{
    display: flex;
    align-items: start;
  }
}

.settings-card {
  width: 736px;

  &__content{
    border: 2px solid colors.$slightly-gray;
    padding: 32px 24px;
  }
}

.support-info {
  width: 360px;
  margin-left: 32px;

  &__user {
    display: flex;
    border: 1px solid colors.$border-gray;
    padding: 12px;
    align-items: center;
  }

  &__userName {
    margin-left: 16px;
  }
}
