@use '../../../../../styles/colors.scss';


.right-panel-item{

  border-bottom: 1px solid colors.$slightly-gray;
  padding: 0 8px;
  min-height: 65px;

  &_isCompleted {
    background: colors.$lime;
  }

  &_noLimeColor {
    background: transparent;
  }

  &__text {
    display: flex;
    align-items: center;

    &_noData{
      padding-left: 32px;
    }
  }

  &__details {
    list-style: none;


    &-item {
      display: flex;
      justify-content: space-between;
      align-items: start;
      position: relative;
      gap: 24px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 12px;
        transform: translateY(-50%);
        height: 1px;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
        z-index: 0;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }

    }

    &:not(:last-child) {
      border-bottom: 1px solid colors.$slightly-gray;
      padding: 0 0 16px;
      margin: 0 0 16px;
    }

    &:last-child {
      padding: 0;
      margin: 0 0 24px;
    }
  }

}
