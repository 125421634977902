@use '../../../styles/colors.scss';

.calculations {

  margin-bottom: 32px;
  margin-top: 40px;

  @media screen and (max-width: 1024px)  {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1024px)  {
    margin-left: 32px;
  }

  &__count {
    color: colors.$strong-gray;
    font-weight: inherit;
    margin-left: 8px;
  }

  &__container{
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  &__filters {
    width: 360px;
    margin-left: 32px;
    margin-bottom: 32px;
  }
}

.calculations-results {

  width: 736px;
  margin-bottom: 32px;

  &__sorting {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  &__search{
    margin-bottom: 32px;
    margin-top: 4px;
  }

  &__select-count{
    width: 184px;
    background: colors.$white;
  }

  &__select-sorting{
    width: 346px;
    background: colors.$white;
  }
}
