// new style

$light-text-disabled: #999999;
$light-text-secondary: #666666;
$light-text-disabled-opacity: rgb(0, 0, 0, 0.26);
$light-outlined-border: rgb(0, 0, 0, 0.23);
$grey-50: #FAFAFA;
$grey-200: #EEEEEE;
$light-action: rgb(0, 0, 0, 0.54);

//  COMMON
$light-gray: rgba(0, 0, 0, 0.6);
$slightly-gray: rgba(0, 0, 0, 0.12);
$black: rgba(0, 0, 0, 0.87);
$black-dark: rgba(0, 0, 0, 1);
$dark-gray: #E0E0E0;
$medium-gray: #666;
$dark-gray-tooltip: rgba(97, 97, 97, 0.9);
$border-gray: rgba(0, 0, 0, 0.23);
$strong-gray: #BDBDBD;
$light-blue: #e6f3fa;
$light-blue-opacity: rgba(25, 118, 210, 0.08);
$blue: #1976D2;
$blue-dark: #0288D1;
$blue-focus: #005FCC;

$beige: #F5F5F5;
$light-beige: #FAFAFA;
$dark-red: #D32F2F;
$red: #DC2626;
$blue-gray: #ECEFF1;
$orange: #ED6C02;
$lime: #F9FBE7;
$white: #FFF;
$green: #4CAF50;
$green-dark: #2E7D32;
$yellow: #FFB400;

//  STATUSES
$error: $red;
$warning: #FF9800;
$info: #2563EB;

:export {
  LIGHT_GRAY: $light-gray;
  LIGHT_OUTLINED_BORDER: $light-outlined-border;
  DARK_GRAY: $dark-gray;
  MEDIUM_GRAY: $medium-gray;
  DARK_GRAY_TOOLTIP: $dark-gray-tooltip;
  SLIGHTLY_GRAY: $slightly-gray;
  BLACK: $black;
  BLACK_DARK: $black-dark;
  BLUE: $blue;
  BLUE_DARK: $blue-dark;
  BLUE_FOCUS: $blue-focus;
  RED: $red;
  BEIGE: $beige;
  LIGHT_BEIGE: $light-beige;
  LIGHT_BLUE: $light-blue;
  DARK_RED: $dark-red;
  BLUE_GRAY: $blue-gray;
  ORANGE: $orange;
  LIME: $lime;
  BORDER_GRAY: $border-gray;
  WHITE: $white;
  GREEN: $green;
  YELLOW: $yellow;
  STRONG_GRAY: $strong-gray;
  GRAY_50: $grey-50;
  LIGHT_TEXT_SECONDARY: $light-text-secondary;
  LIGHT_TEXT_DISABLED: $light-text-disabled;
  LIGHT_TEXT_DISABLED_OPACITY: $light-text-disabled-opacity;
  WARNING: $warning;
}
