.dictionaries {
  margin-bottom: 28px;
  padding-top: 32px;
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1024px) {
    margin-left: 32px;
    margin-right: 32px;
  }
}
