@use './colors.scss';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&family=Roboto:wght@100;300;400;500;700;900&display=swap');


* {
  box-sizing: border-box;
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 400;
}

a {
  text-decoration: none;
}

b {
  font-weight: 500;
}

h1, h2, h3, h4, h5, h6, p, span {
  margin: 0;
}

h1 {
  font-style: normal;
  font-size: 40px;
  line-height: 48px;
}
