@use '../../../styles/colors.scss';

.print {
  color: colors.$black;
  margin: 32px 24px;
  max-width: 912px;

  &__hr {
    border: 2px solid;
    margin: 0;
  }

  &__hr-small{
    border: 1px solid;
    margin: 0;
  }

  &__subItem:not(:last-child) {
    margin-bottom: 16px;
  }

  &__list {
    list-style: none;
    padding: 22px 0;
    margin: 0;
    border-bottom: 1px solid colors.$border-gray;
    display: flex;
    flex-wrap: wrap;

    .print__item {
      width: 50%;
    }
  }

  &__item {
    width: 50%;
    &:not(:last-child){
      margin-bottom: 16px;
    }
  }

  &__image{
    margin-bottom: 26px;
  }

  &__table{
    margin: 28px 0 32px;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    td {
      padding-left: 16px;
      border-bottom: 1px solid colors.$border-gray;
      height: 32px;
    }

    &:not(&_product) {
      td:first-child {
        width: 49%;
      }
    }

    &_product {
      width: 96%;
      margin: 0 0 0 auto;
      border-top: 1px solid colors.$border-gray;

      td:first-child {
        width: 46%;
      }
    }
  }

  &__tbody{
    break-inside: avoid;
  }

  &__invoice{
    margin: 32px 0 0 24px;

    .print__list{
      width: 97%;
      border-bottom: none;
    }

    .print__table td:first-child{
      width: 47.5%;
    }
  }

  &__invoice-container{
    break-inside: avoid;
  }
}
