.dictionary__modal-add-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px 24px;

  &__currency {
    &_isEdit {
      grid-column: span 2
    }
  }
}
