@use '../../../../../../../styles/colors.scss';

.modal-search-container{
  background: colors.$blue-gray;

  &_padding{
    padding-top: 24px;
  }
}

.search-results {

  padding: 24px 24px 32px;

  &__select-count{
    width: 184px;
    background: colors.$white;
  }

  &__select-sorting{
    width: 324px;
    background: colors.$white;
  }

  &__slider-data{
    display: flex;
    justify-content: space-between;
    color: colors.$light-gray;
    margin-top: 4px;
  }

  &__filter {
    &_yellow {
      background: colors.$lime;
    }
  }

  &__input{
    background: colors.$white;
  }

  &__container{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__filters{
    width: 360px;
  }

  &__results {
    width: 692px;
  }

  &__sorting {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
}

.search-filter {

  &__control-label{
    height: 30px;

    &:not(:last-child){
      margin-bottom: 10px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    padding-left: 4px;
    font-size: 16px;

    svg {
      margin-left: 8px;

      path {
        fill-opacity: 0.87;
      }
    }
  }
}
