.right-panel {
  width: 360px;
  margin: 92px 0 64px 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 32px;
}

.right-panel-buttons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 360px;
  left: 1056px;
  top: 88px;

  svg {
    font-size: 24px !important;
  }
}
