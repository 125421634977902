@use '../../../styles/colors.scss';

.modal-common {
  $width: 600px;
  width: $width;
  left: calc(50vw - ($width / 2));

  top: 50%;
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  padding: 32px;
  background-color: colors.$white;
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);

  &__description {
    max-width: 85%;
  }

  &__actions {
    display: flex;
    margin: 24px 0 0 auto;


    &_confirm {
      box-shadow: none !important;
    }
  }
}
