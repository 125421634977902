@use '../../../../../../../../../../../styles/colors';

.customs-declaration {

  background: colors.$light-beige;
  padding: 24px 24px 0;
  border: 1px dashed colors.$slightly-gray;

  &:not(:last-child) {
    margin-bottom: 24px;
  }


  &__title {
    display: flex;
  }

  &__item {
    width: 298px;
    margin-top: 18px;

    &_two-row{
      display: grid;
      grid-gap: 18px 0;
    }
  }

  &__changeVat{
    margin-top: 8px;
    display: flex;
    align-items: center;
  }


  &__value-container{
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }
  }

  &__link {
    cursor: pointer;
  }

  &__linkModalChooseVat {
    display: block;
    cursor: pointer;
  }

  &__container{
    flex-basis: 298px;
    margin-bottom: 16px;
  }

  &__column{

    width: 298px;

    &-right{
      display: grid;
      gap: 22px 0;
    }
  }

  &__option{
    display: flex;
    align-items: baseline;
  }

  &__row-buttons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 24px;
  }

  svg {
    cursor: pointer;
  }

  &__subtitle{
    display: flex;
    justify-content: space-between;
  }

  &__list {
    padding: 0;
    list-style: none;
    display: flex;
    margin-bottom: 0;
    margin-top: 0;
    justify-content: space-between;
  }


  &__row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 14px;

    &::before {
      content: '';
      position: absolute;
      left: calc(100% - 100%);
      right: 0;
      bottom: 4px;
      transform: translateY(-50%);
      height: 1px;
      border-bottom: 1px dashed colors.$slightly-gray;
      z-index: 0;
    }

    &_isBig{
      margin-bottom: 20px;
    }


    &_isSmall{
      margin-bottom: 0;
    }

    &_isBig, &_isSmall{
      &::before {
        bottom: 7px;
      }
    }

    p, div {
      background: colors.$light-beige;
      z-index: 1;

      &:first-child {
        padding: 0 4px 0 0;
      }
    }

  }

  &__list-item{
    width: 48%;
  }


  &__goodsName{
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.42);
    text-decoration-style: dashed;
    text-underline-offset: 4px;
    line-height: 1.6;
    font-weight: 500;
  }

  &__link{
    cursor: pointer;
  }

  &__text-with-tooltip{
    font-weight: 500;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.42);
    cursor: pointer;
  }
}
