@use '../../../../../../../../../../../styles/colors';

.payments-tab{

  &__main{
    font-weight: 500;
    display: block;
    height: 18px;
  }

  &__additional{
    color: colors.$light-text-disabled;
    font-size: 12px;
    display: block;
    margin-top: 4px;
    height: 15px;
  }
}
